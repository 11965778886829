@media only screen and (max-width: 87.5rem) {
  table.dataTable tbody td {
    font-size: 0.875rem;
    padding: 1.25rem 0.9375rem;
  }
}

.pointer:hover {
  cursor: pointer;
}

textarea.form-control {
  min-height: 5rem !important;
}

.lds-dual-container {
  display: flex;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fed;
  border-color: #fed transparent #fed transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tox.tox-tinymce {
  border-radius: 0.7rem;
}

.img-fluid.logo-abbr {
  width: 10rem !important;
  max-width: unset;
}

@media (max-width: 768px) {
  .img-fluid.logo-abbr {
    max-width: 2.8125rem !important;
  }
}

@media (max-width: 811px) {
  .img-fluid.logo-abbr {
    max-width: 2.8125rem !important;
  }
}
